<template>
  <div v-if="$isLoading">
    <b-row class="no-gutters">
      <b-col>
        <h1 class="font-weight-bold header-main text-uppercase mb-3">
          สร้าง / แก้ไขหน้าแรก
        </h1>
      </b-col>
    </b-row>
    <div class="bg-white p-3">
      <b-row>
        <b-col md="6">
          <InputText
            textFloat="ชื่อ"
            placeholder="ชื่อ"
            type="text"
            v-model="form.entity.name"
            @onKeyup="handleTemplateDetail"
            :isValidate="$v.form.entity.name.$error"
            :v="$v.form.entity.name"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <DateTimePicker
            textFloat="เวลาที่เริ่ม"
            type="datetime"
            placeholder="กรุณาเลือกวันที่และเวลา"
            @input="handleTemplateDetail"
            v-model="form.entity.startDateTime"
            :isValidate="$v.form.entity.startDateTime.$error"
            :v="$v.form.entity.startDateTime"
          />
        </b-col>
        <b-col md="6">
          <DateTimePicker
            textFloat="เวลาที่จบ"
            type="datetime"
            @input="handleTemplateDetail"
            placeholder="กรุณาเลือกวันที่และเวลา"
            v-model="form.entity.endDateTime"
            :isValidate="$v.form.entity.endDateTime.$error"
            :v="$v.form.entity.endDateTime"
          />
        </b-col>
      </b-row>
    </div>
    <div class="bg-white p-3 mt-3">
      <b-row class="no-gutters mb-3">
        <div class="d-flex justify-content-between w-100">
          <b-input-group class="panel-input-serach">
            <b-form-input
              class="input-serach"
              placeholder="ค้นหา"
              v-model="filter.Search"
              @keyup="handleSearch"
            ></b-form-input>
            <b-input-group-prepend @click="btnSearch">
              <span class="icon-input m-auto pr-2">
                <font-awesome-icon icon="search" title="View" />
              </span>
            </b-input-group-prepend>
          </b-input-group>
          <b-button class="btn-main" @click="openModal()">นำเข้า</b-button>
        </div>
      </b-row>
      <b-row class="no-gutters">
        <b-col>
          <b-table
            striped
            responsive
            hover
            :items="form.sections"
            :fields="fields"
            :busy="isBusy"
            show-empty
            empty-text="ไม่พบข้อมูล"
            class="table-list"
          >
            <template v-slot:cell(sortOrder)="data">
              <div
                class="d-flex align-items-center justify-content-center mt-3"
              >
                <InputText
                  textFloat=""
                  placeholder="กรุณากรอกข้อมูล"
                  type="number"
                  v-model="data.item.sortOrder"
                  @onKeyup="clearValidate()"
                  :isValidate="
                    $v.form.sections.$each.$iter[data.index].sortOrder.$error ||
                    data.index == indexError
                  "
                  :v="$v.form.sections.$each.$iter[data.index].sortOrder"
                />
              </div>
            </template>
            <template v-slot:cell(id)="data">
              <div class="d-flex justify-content-center">
                <b-button
                  variant="link"
                  @click="edit(data.item)"
                  class="text-dark px-1 py-0"
                >
                  แก้ไข
                </b-button>
                <b-button
                  variant="link"
                  @click="openModalDelete(data.item, data.index)"
                  class="text-dark px-1 py-0"
                >
                  ลบ
                </b-button>
              </div>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-black my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row class="no-gutters px-3 px-sm-0">
        <b-col
          class="form-inline justify-content-center justify-content-sm-between"
        >
          <div class="d-sm-flex">
            <b-pagination
              v-model="filter.PageNo"
              :total-rows="rows"
              :per-page="filter.PerPage"
              class="m-md-0"
              @change="pagination"
              align="center"
            ></b-pagination>
          </div>
          <b-form-select
            class="select-page"
            v-model="filter.PerPage"
            @change="hanndleChangePerpage"
            :options="pageOptions"
          ></b-form-select>
        </b-col>
      </b-row>
      <b-row
        class="no-gutters px-3 px-sm-0 pb-3 mt-3"
        v-if="$v.form.sections.$error && !$v.form.sections.required"
      >
        <span class="text-error">กรุณาเพิ่มข้อมูล</span>
      </b-row>
    </div>

    <div class="bg-white mt-3">
      <b-row class="mt-3 mb-4">
        <b-col>
          <b-row class="px-3">
            <b-col class="d-flex justify-content-between align-items-center">
              <div class="d-sm-flex my-3 align-items-center">
                <label class="font-weight-bold main-label">
                  การใช้งาน
                  <span class="text-danger">*</span>
                </label>
                <div>
                  <b-form-checkbox
                    switch
                    class="radio-active ml-3"
                    size="lg"
                    v-model="form.entity.status"
                  >
                    <span class="ml-2 main-label">
                      {{ form.entity.status ? "เปิด" : "ปิด" }}
                    </span>
                  </b-form-checkbox>
                </div>
              </div>
              <div class="d-flex justify-content-end">
                <router-link :to="'/setting-homepage'">
                  <b-button class="button btn-cancel ml-2"
                    >ย้อนกลับ</b-button
                  ></router-link
                >
                <b-button class="btn-main ml-3" @click="checkForm()"
                  >บันทึก</b-button
                >
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <ModalBanner ref="modalBanner" :id="id" @handleData="handleData" />
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalLoading ref="modalLoading" :hasClose="false" />
    <ModalAlertConfirm
      msg="ยืนยันการลบ ?"
      :text="modalMessage"
      btnConfirm="ลบ"
      colorBtnConfirm="danger"
      btnCancel="ยกเลิก"
      ref="ModalAlertConfirm"
      @confirm="btnDelete"
    />
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalLoading from "@/components/modal/alert/ModalLoading";
import DateTimePicker from "@/components/inputs/DateTimePicker";
import ModalBanner from "./ModalBanner.vue";
import { required, minValue } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";

const minDate = (value, fromDate) => {
  if (!value) return true;
  let minDate = fromDate;
  return new Date(value) > new Date(minDate);
};
const maxDate = (value, toDate = null) => {
  let maxDate = toDate || new Date();
  return new Date(maxDate) > new Date(value);
};

export default {
  components: {
    InputText,
    ModalAlert,
    ModalAlertError,
    ModalAlertConfirm,
    ModalLoading,
    DateTimePicker,
    ModalBanner,
  },
  data() {
    return {
      id: this.$route.params.id,
      isDisable: false,
      modalMessage: "",
      indexError: null,
      form: {},
      isBusy: false,
      rows: 0,
      fields: [
        {
          key: "name",
          label: "ชื่อ",
          class: "w-100px",
        },
        {
          key: "type",
          label: "รูปแบบ",
          class: "w-200",
        },
        {
          key: "sortOrder",
          label: "Sort Order",
          class: "w-100px",
        },
        {
          key: "id",
          label: "",
        },
      ],
      filter: {
        PageNo: 1,
        PerPage: 10,
        Search: "",
      },
      pageOptions: [
        { value: 10, text: "10 / หน้า" },
        { value: 30, text: "30 / หน้า" },
        { value: 50, text: "50 / หน้า" },
        { value: 100, text: "100 / หน้า" },
      ],
      requestDeleteBanner: {
        id: null,
      },
      duplicateArray: [],
    };
  },
  validations: {
    form: {
      entity: {
        name: { required },
        startDateTime: {
          required,
          maxDateTime(val, { endDateTime }) {
            return maxDate(val, endDateTime);
          },
        },
        endDateTime: {
          required,
          minDateTime(val, { startDateTime }) {
            return minDate(val, startDateTime);
          },
        },
      },
      sections: {
        required,
        $each: {
          sortOrder: {
            required,
          },
        },
      },
    },
  },
  computed: {
    ...mapState({
      templateDetail: (state) => state.templateDetail,
    }),
  },
  created: async function () {
    await this.getData();
  },
  methods: {
    async handleData(list) {
      if (this.id == 0) {
        this.duplicateArray.push(list);
        this.form.sections = this.duplicateArray;
        this.handleTemplateDetail();
        this.filterPagination();
      } else {
        this.$refs.modalLoading.show();
        let data = await this.$callApi(
          "post",
          `${this.$baseUrl}/api/Template/Detail/${this.id}`,
          null,
          this.$headersSetting,
          [list]
        );
        if (data.result == 1) {
          await this.getData();
        }
        this.$refs.modalLoading.hide();
      }
    },
    edit(data) {
      this.$store.commit("setTemplateId", this.id);
      switch (data.typeId) {
        case 1:
          this.$router.push({
            path: `/setting-banner/details/${data.id}`,
          });
          break;
        case 4:
          this.$router.push({
            path: `/setting-product/details/${data.id}`,
          });
          break;
        case 5:
          this.$router.push({
            path: `/setting-news/details/${data.id}`,
          });
          break;
        default:
          break;
      }
    },
    // async checkDuplicate(arr, property) {
    //   const seen = [];
    //   for (let i = 0; i < arr.length; i++) {
    //     const obj = arr[i];
    //     const index = seen.findIndex(
    //       (item) => item[property] === obj[property]
    //     );
    //     if (index !== -1) {
    //       return index;
    //     }
    //     seen.push(obj);
    //   }
    //   return null;
    // },
    openModal() {
      this.$refs.modalBanner.show();
    },
    clearValidate() {
      this.indexError = null;
    },
    handleTemplateDetail() {
      let returnedTarget = JSON.parse(JSON.stringify(this.form));
      returnedTarget.sections = this.duplicateArray;
      this.$store.commit("setTemplateDetail", returnedTarget);
    },
    getData: async function () {
      this.$isLoading = false;
      let data = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/Template/${this.id}`,
        null,
        this.$headersSetting,
        this.filter
      );

      if (data.result == 1) {
        this.form = data.detail;
        if (this.filter.PageNo == 1) {
          this.rows = this.form.sectionCount;
        }
        this.form.entity.startDateTime = `${
          data.detail.entity.startDateTime.split(" ")[0]
        }T${data.detail.entity.startDateTime.split(" ")[1]}.000+07:00`;
        this.form.entity.endDateTime = `${
          data.detail.entity.endDateTime.split(" ")[0]
        }T${data.detail.entity.endDateTime.split(" ")[1]}.000+07:00`;
      } else if (this.id == 0) {
        this.form = this.templateDetail;
        this.duplicateArray = this.templateDetail.sections;
        this.filterPagination();
      }
      this.$v.form.$reset();
      this.$isLoading = true;
    },
    openModalDelete(value, index) {
      if (this.id == 0) {
        this.duplicateArray.splice(index, 1);
        this.form.sections = this.duplicateArray;
        this.filterPagination();
      } else {
        this.requestDeleteBanner.id = value.id;
        this.requestDeleteBanner.sortOrder = value.sortOrder;
        this.modalMessage = "คุณต้องการลบ " + value.name + " ใช่หรือไม่?";
        this.$refs.ModalAlertConfirm.show();
      }
    },
    btnDelete: async function () {
      this.$refs.ModalAlertConfirm.hide();
      let resData = await this.$callApi(
        "delete",
        `${this.$baseUrl}/api/Template/Detail/${this.id}/${this.requestDeleteBanner.id}/${this.requestDeleteBanner.sortOrder}`,
        null,
        this.$headersSetting,
        null
      );

      this.modalMessage = resData.message;
      if (resData.result == 1) {
        this.$refs.modalAlert.show();
        this.filter.PageNo = 1;
        setTimeout(() => {
          this.$refs.modalAlert.hide();
        }, 1500);
        await this.getData();
      } else {
        this.$refs.modalAlertError.show();
      }
    },
    filterData() {
      this.form.sections = [];
      if (this.filter.Search) {
        for (const e of this.duplicateArray) {
          if (e.name.toLowerCase().includes(this.filter.Search)) {
            this.form.sections.push(e);
          }
        }
        this.rows = this.form.sections.length;
      } else {
        this.filterPagination();
      }
    },
    filterPagination() {
      this.rows = this.duplicateArray.length;
      let offset =
        this.filter.PageNo > 0
          ? (this.filter.PageNo - 1) * this.filter.PerPage
          : 0;
      let limit = this.filter.PerPage + offset;
      let arr = [];
      for (let index = 0; index < this.duplicateArray.length; index++) {
        if (index >= offset && index < limit) {
          arr.push(this.duplicateArray[index]);
        }
      }
      this.form.sections = arr;
    },
    pagination(Page) {
      this.filter.PageNo = Page;
      if (this.id == 0) {
        this.filterPagination();
      } else this.getData();
    },
    hanndleChangePerpage(value) {
      this.filter.PageNo = 1;
      this.filter.PerPage = value;
      if (this.id == 0) {
        this.filterPagination();
      } else this.getData();
    },
    handleSearch() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.filter.PageNo = 1;
        if (this.id == 0) {
          this.filterData();
        } else this.getData();
      }, 800);
    },
    btnSearch() {
      this.filter.PageNo = 1;
      if (this.id == 0) {
        this.filterData();
      } else this.getData();
    },
    checkForm: async function () {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        if (document.querySelector(".input-custom.error")) {
          this.$nextTick(() => {
            let domRect = document
              .querySelector(".input-custom.error")
              .getBoundingClientRect();
            window.scrollTo(
              domRect.left + document.documentElement.scrollLeft,
              domRect.top + document.documentElement.scrollTop - 150
            );
          });
        }
        return;
      }
      this.form.sections.map((e) => {
        e.sortOrder = parseInt(e.sortOrder);
        return e;
      });
      // this.indexError = await this.checkDuplicate(
      //   this.form.sections,
      //   "sortOrder"
      // );
      // if (this.indexError != null) {
      //   this.modalMessage = "กรุณากรอกข้อมูลใหม่";
      //   this.$refs.modalAlertError.show();
      //   if (document.querySelector(".input-custom.error")) {
      //     this.$nextTick(() => {
      //       let domRect = document
      //         .querySelector(".input-custom.error")
      //         .getBoundingClientRect();
      //       window.scrollTo(
      //         domRect.left + document.documentElement.scrollLeft,
      //         domRect.top + document.documentElement.scrollTop - 150
      //       );
      //     });
      //   }
      //   return;
      // }
      await this.submit();
    },
    submit: async function () {
      this.isDisable = true;
      this.$refs.modalLoading.show();

      let data = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/Template/Save`,
        null,
        this.$headersSetting,
        this.form
      );

      this.$refs.modalLoading.hide();
      this.modalMessage = data.message;
      this.isDisable = false;
      if (data.result == 1) {
        this.$refs.modalAlert.show();
        setTimeout(() => {
          this.$refs.modalAlert.hide();
          this.$router.push({
            path: `/setting-homepage`,
          });
        }, 1500);
      } else {
        this.$refs.modalAlertError.show();
      }
    },
  },
};
</script>

<style scoped>
::v-deep .panel-input-serach {
  border: 1px solid #bcbcbc !important;
  max-width: 350px;
}
::v-deep .card {
  margin-bottom: 0 !important;
}
.text-error {
  color: #ff0000;
  font-size: 14px;
}
</style>
